import { SEO, PageContainer, Text } from '@components'

import styles from './style.module.css'

function Custom404Module() {
  return (
    <PageContainer className={styles.container}>
      <SEO title="Not Found" description="This page does not exist." noIndex />

      <Text tag="span" variant="subtitle" className={styles.block}>
        Hmm,
      </Text>
      <Text tag="span" variant="subtitle" className={styles.block}>
        This page does not exist.
      </Text>
    </PageContainer>
  )
}

export default Custom404Module
