import { serverSideTranslations } from 'next-i18next/serverSideTranslations'

import Custom404Module from '@modules/404'

export default function Custom404Page() {
  return <Custom404Module />
}

export async function getStaticProps(ctx) {
  return {
    props: { ...(await serverSideTranslations(ctx.locale, ['common'])) },
  }
}
